import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import FormWrapper from '../FormWrapper/FormWrapper'
import JotformEmbed from 'react-jotform-embed'

const PartnerContactForm = () =>
  <SectionWrapper className='partner-contact-form'>
    <div className='partner-contact-form__header'>
      Kontaktformular
    </div>
    <FormWrapper
      leftSide={<JotformEmbed src='https://form.jotform.com/220481954379364' />}
    />
  </SectionWrapper>

export default PartnerContactForm
