import React from 'react'
import Layout from '../components/Layout/Layout'
import { graphql } from 'gatsby'
import PartnershipHeader from '../components/PartnershipHeader/PartnershipHeader'
import PartnerContactForm from '../components/PartnerContactForm/PartnerContactForm'

const PartnershipPage = ({ data }) => {
  const { strapiPartnershipPage: { headerTiles }, description } = data

  return <Layout description={description}>
    <PartnershipHeader headerTiles={headerTiles} />
    <PartnerContactForm />
  </Layout>
}

export default PartnershipPage

export const query = graphql`
  query PartnershipPageQuery {
    strapiPartnershipPage {
      description
      ...partnershipHeaderFragment
    }
  }
`
