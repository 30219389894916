import React from 'react'
import PartnershipHeaderTile from './PartnershipHeaderTile'
import PropTypes from 'prop-types'
import { PartnershipTilesPropType } from '../propTypes'

const PartnershipHeader = ({ headerTiles }) => <>
  <div className='partnership-header-background'/>
  <div className='partnership-header-gradient-bar'/>
  <div className='partnership-header'>
    <div className='partnership-header-tiles'>
      {headerTiles?.map((tile => <PartnershipHeaderTile
        {...tile}
        key={tile.header}
      />))}
    </div>
  </div>
</>

export default PartnershipHeader

PartnershipHeader.propTypes = {
  headerTiles: PropTypes.arrayOf(PartnershipTilesPropType)
}

PartnershipHeader.defaultProps = {
  headerTiles: []
}
