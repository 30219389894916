import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { LinkButtonPropType, StrapiImageSharpPropType } from '../propTypes'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const PartnershipHeaderTile = ({ image, header, description, button }) => {
  const gatsbyImageData = image?.localFile?.childImageSharp?.gatsbyImageData

  return <div className='partnership-header-tile'>
    <GatsbyImage
      alt={image?.alternativeText}
      image={gatsbyImageData}
    />
    <div className='partnership-header-tile__header'>{header}</div>
    <div className='partnership-header-tile__description'>{description}</div>
    <Link
      className='partnership-header-tile__button'
      to={button?.href}
    >
      {button?.label}
    </Link>
  </div>
}

export default PartnershipHeaderTile

PartnershipHeaderTile.propTypes = {
  image: StrapiImageSharpPropType,
  header: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.arrayOf(LinkButtonPropType),
}

PartnershipHeaderTile.defaultProps = {
  image: {},
  header: '',
  description: '',
  button: {},
}
